import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const
  InfoPublica = lazy(() => import('../../../views/InfoPublica/index')),
  TransparenciaPresupuestaria = lazy(() => import('../../../views/InfoPublicaTransPresupuestaria/index')),
  DetalleNoticias = lazy(() => import('../../../views/NoticiasPublic/DetalleMicroCapsulas'))


const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/files/:code`} component={DetalleNoticias} />
      <Route path={`${props.match.path}/files`} component={InfoPublica} />
      <Route path={`${props.match.path}/solicitar-informacion`} component={InfoPublica} />
      <Route path={`${props.match.path}/ley-de-accesso`} component={InfoPublica} />
      <Route path={`${props.match.path}/transparencia-presupuestaria`} component={TransparenciaPresupuestaria} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
